import { graphql, Link, useStaticQuery } from 'gatsby'
import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import Footer from '../components/Footer'
import Freequote from '../components/Freequote'
import Hero from '../components/Hero'
import Layout from '../components/Layout'
import Location from '../components/Location'
import SEO from '../components/seo'
import SlideCard from '../components/SlideCard'

const Equipment = () => {
  const data = useStaticQuery(
    graphql`
      query {
        desktop: file(relativePath: { eq: "equipment-header.png" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        containers: file(relativePath: { eq: "containers.png" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 500, maxHeight: 550) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        dryvan: file(relativePath: { eq: "reefer-truck.png" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 500, maxHeight: 550) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        tempcontainer: file(relativePath: { eq: "temp-container.png" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 500, maxHeight: 550) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        mobilestorage: file(relativePath: { eq: "mobile-storage.png" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 500, maxHeight: 550) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        flatbedsec: file(relativePath: { eq: "flatbed-sec.png" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 500, maxHeight: 550) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        chassistrailer: file(relativePath: { eq: "chassis-trailer.png" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 500, maxHeight: 550) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `
  )

  const equipmentheader = data.desktop.childImageSharp.fluid
  const containers = data.containers.childImageSharp.fluid
  const dryvan = data.dryvan.childImageSharp.fluid
  const tempcontainer = data.tempcontainer.childImageSharp.fluid
  const mobilestorage = data.mobilestorage.childImageSharp.fluid
  const flatbedsec = data.flatbedsec.childImageSharp.fluid
  const chassistrailer = data.chassistrailer.childImageSharp.fluid

  return (
    <Layout>
      <SEO
        title="Storage Trailers | Flatbeds | Chassis Trailers | Temperature Controlled Trailers"
        description="RoadLINX offer Storage Trailers, Flatbeds, Chassis Trailers and Temperature Controlled Trailers. call RoadLINX today at 905-760-1141 to find out more."
      />
      <Hero type="primary" image={equipmentheader} title="Equipment" />
      <section className="equipment">
        <Container className="equipment__container" fluid>
          <Row>
            <Col className="equipment__col mb-0">
              <div className="equipment__sub-heading">
                International Freight Shipping Services
              </div>
              <div className="equipment__heading">
                Our Equipment – Storage Trailers
              </div>
              <div className="equipment__text">
                As a top Freight Trucking & International Shipping company in
                North America, RoadLINX brings you the best in shipping
                containers, trailers and equipment to ensure the safety and
                timely arrival of all your shipments. Choose from a wide range
                of shipping methods and compartments to solve all your freight
                logistics concerns. Our equipment includes:
              </div>
            </Col>
          </Row>
          <Row className="equipment__row">
            <Col className="equipment__col" xl={4}>
              <Link to="/equipment/intermodal-containers/">
                <SlideCard
                  image={containers}
                  text="Intermodal Containers"
                  overlaytext="Our intermodal containers are built to withstand even the toughest travelling conditions. Whether your shipment is making it across air, land, sea or all three, our containers will keep your items safe and dry."
                />
              </Link>
            </Col>
            <Col className="equipment__col" xl={4}>
              <Link to="/equipment/dry-van/">
                <SlideCard
                  image={dryvan}
                  text="Dry Vans"
                  overlaytext="One of the most popular ways to ship, these vans are designed to keep your items dry and secure by keeping out the elements. Dry vans freight shipping is also one of the most cost-effective ways to ship since they provide safety for travel over land without extra features like temperature control or intermodal containers."
                />
              </Link>
            </Col>
            <Col className="equipment__col" xl={4}>
              <Link to="/equipment/temperature-controlled/">
                <SlideCard
                  image={tempcontainer}
                  text="Temperature Controlled Trailerss"
                  overlaytext="Our temperature controlled trailers(Heated trucking transportation & Refrigerated Freight Shipping) have up to three different compartments with independent temperature control to allow you to ship multiple climate-sensitive items at once. These trailers are a popular choice for medical supplies and perishable items."
                />
              </Link>
            </Col>
          </Row>
          <Row className="equipment__row">
            <Col className="equipment__col" xl={4}>
              <Link to="/equipment/storage-trailers/">
                <SlideCard
                  image={mobilestorage}
                  text="Mobile Storage and Warehousing Trailers"
                  overlaytext="We make warehousing easier than ever with our mobile storage trailers. Choose to park your trailer on your own property or on our secure lot for both long and short term storage solutions. Check out our warehousing solutions."
                />
              </Link>
            </Col>
            <Col className="equipment__col" xl={4}>
              <Link to="/equipment/flatbeds/">
                <SlideCard
                  image={flatbedsec}
                  text="Flatbeds"
                  overlaytext="Flatbed trailer shipping is one of the most common shipping options and for good reason. A variety of customizable options makes flatbeds ideal for a wide range of shipment types including full and less than truck loads and over dimensional loads."
                />
              </Link>
            </Col>
            <Col className="equipment__col" xl={4}>
              <Link to="/equipment/chassis-types/">
                <SlideCard
                  image={chassistrailer}
                  text="Chassis Trailers"
                  overlaytext="Ranging from 20’ to 40’, our chassis trailers are designed to last and help you haul even the most difficult shipments. Choose from combo trailers, gooseneck trailers, and heavy duty 24-metric ton trailers."
                />
              </Link>
            </Col>
          </Row>
        </Container>
      </section>
      <Freequote />
      <Location />
      <Footer />
    </Layout>
  )
}

export default Equipment
